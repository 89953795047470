<template>
  <div style="height:100%">
    <v-col style="height:100%">
      <v-row class="d-flex justify-center" style="height:10%">
        <div class="ma-5" style="min-width:8%">
        </div>
        <v-btn 
          class="ma-5" 
          style="min-width:8%;background-image: linear-gradient(45deg, rgb(138, 119, 226, 0.8), rgb(80, 191, 225));" 
          color="white" 
          dark
          @click="openAlertDialog"
          :loading="loading"
        >
          Salva
        </v-btn>
      </v-row>
      <v-row style="height:100%;overflow-y: auto;" class="pa-5 d-flex justify-center">
        <TypeDataTable
          style="width:90%; height:85%"
          v-model="selected"
          loading-text="Caricamento prodotti..."
          :headers="headers"
          :loading="loading"
          :items="items"
          disable-pagination
          :select-on-row-click="false"
          :show-actions="false"
          :show-select="false"
          no-data-text="Nessun Prodotto Trovato"
          :search="search"
          :custom-filter="nameOrBarcodeFilter"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Nome Prodotto o Barcode"
              dense
              outlined
            ></v-text-field>
          </template>
          <template v-slot:custom-actualQuantity="{ item }">
            <v-text-field v-if="!item.alreadyExist" min="0" max="999999" type="number" v-model="item.actualQuantity" maxlength="2" @input="graterThan0(item)"></v-text-field>
            <v-text-field v-else min="0" type="number" v-model="item.qtyInStock" disabled></v-text-field>
          </template>

          <template v-slot:custom-tags="{ item }">
            <v-chip v-if="!!item.tags">
              {{ item.tags[0].description }}
            </v-chip>
          </template>

          <template v-slot:custom-qtyInStock="{ item }">
            <v-chip v-if="item['qtyInStock'] <= 0" color="error">
              {{ item["qtyInStock"] ? item["qtyInStock"] : 0 }}</v-chip
            >

            <v-chip
              v-else-if="
                Number(item['qtyInStock']) < Number(item['qtyMinStock'])
              "
              color="orange"
            >
              {{ item["qtyInStock"] ? item["qtyInStock"] : 0 }}</v-chip
            >
            <v-chip v-else-if="item['qtyInStock'] > 0" color="success">
              {{ item["qtyInStock"] }}</v-chip
            >
          </template>

          <template v-slot:custom-supplier="{ item }">
            {{ item.supplier.businessName }}
          </template>
        </TypeDataTable>
      </v-row>
    </v-col>
    <StandardDialog
      v-model="alertDialog"
      title="Attenzione"
      :dialog-height="null"
      dialog-width="500px"
    >
      <div>
        Attenzione! Sei sicuro di voler modificare {{getNumberOfProduct()}} prodotti?
      </div>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="() => { alertDialog = false }"
        >
          Annulla
        </v-btn>
        <v-btn
          color="default"
          text
          @click="saveAdjustments"
        >
          Salva
        </v-btn>
      </template>
    </StandardDialog>
  </div>
</template>
<script>
import SuppliersAutocomplete from "@/components/common/SuppliersAutocomplete.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import MovementsAdjustmentsService from "@/services/warehouse/movementAdjustments.service.js";
import StandardDialog from '@/components/common/StandardDialog.vue';
import Vue from "vue";

export default {
  name: "EditMovementAdjustments",
  components: {
    SuppliersAutocomplete,
    TypeDataTable,
    StandardDialog
  },
  props: {
    bus: {
      type: Object,
      default: () => new Vue()
    }
  },
  data: function () {
    return {
      adjustment: undefined,
      loading: false,
      headers: [
        { text: 'Nome Prodotto', value: 'description' },
        { text: 'Barcode', value: 'code' },
        { text: 'Fornitore', value: 'supplier', type: 'custom' },
        { text: "Prezzo netto d'acquisto", value: 'priceNetPurchase', type: 'price' },
        { text: 'Famiglia', value: 'tags', type: 'custom' },
        { text: 'Giacenza Attuale', value: 'qtyInStock', type: 'custom' },
        { text: 'Giacenza Rilevata', value: 'actualQuantity', type: 'custom' },
      ],
      items: [],
      selected: [],
      loading: false,
      alertDialog: false,
      search: '',
    };
  },
  mounted: function () {
    let self = this
    this.bus.$on("editMovementAdjustments", (id) => {
      self.getItems(id);
    });
  },
  beforeDestroy: function () {
  },
  methods: {
    async getItems(adjustmentId) {
      this.loading = true;

      this.adjustment = await MovementsAdjustmentsService.get(adjustmentId)

      this.items = await MovementsAdjustmentsService.getProductsBySupplier(this.adjustment.supplierId);
      this.items = this.items.map(item => {
        let itemAlreadyExist = this.adjustment.movement.movementItems.find(movementItem => movementItem.itemId == item.id)

        item.alreadyExist = !!itemAlreadyExist
        return item
      })

      this.items.sort((a, b) => {
        if (a.alreadyExist && !b.alreadyExist) return 1
        if (!a.alreadyExist && b.alreadyExist) return -1
        return 0
      })

      this.loading = false;
    },
    graterThan0(selected) {
      if (Number(selected.actualQuantity) < 0) 
        selected.actualQuantity = 0
      else if (Number(selected.actualQuantity) > 999999) 
        selected.actualQuantity = 999999
    },
    openAlertDialog() {
      this.alertDialog = true;
    },
    saveAdjustments(){
      this.alertDialog = false
      this.loading = true;
      let items = this.items.map(item => {
        return {
          id: item.id,
          actualQuantity: item.actualQuantity,
          qtyInStock: item.qtyInStock
        }
      }).filter(item => item.actualQuantity != undefined && item.actualQuantity != null)

      let adjustment = this.adjustment
      adjustment.movement.movementItems = undefined
      MovementsAdjustmentsService.addItems(adjustment, items).then(async (res) => {
        this.bus.$emit('refresh-adjustments')
        this.bus.$emit('go-to-adjustments-list')
        this.$delegates.snackbar('Inventario avvenuto con successo')
        this.loading = false;
      });
    },
    getNumberOfProduct() {
      return this.items.filter(item => item.actualQuantity != undefined && item.actualQuantity != null).length;
    },
    nameOrBarcodeFilter (value, search, item) {
      return item.description.toLowerCase().includes(search.toLowerCase()) || item.code == search;
    },
  },
  computed: {
  },
};
</script>

<style>
</style>