

<template>
  <div>
    <FullScreenDialog
      v-model="dialog"
      without-padding
      title="Inventario"
      :route-father="routeFather"
    >
      <template v-slot:toolbar-extension>
        <v-tabs v-model="tab" fixed-tabs centered>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab v-for="tabObj in tabs" :key="tabObj.key"  :disabled="tabObj.name == 'editMovementAdjustments' || tab == 2 || isLicenseBlocked"><v-icon class="mr-2">{{tabObj.icon}}</v-icon>{{ tabObj.title }}</v-tab>
        </v-tabs>
      </template>

      <template v-slot:content>
        <v-tabs-items v-model="tab" style="height:98%">
          <v-tab-item v-for="tabItem in tabs" :key="tabItem.key" style="height:100%" :eager="tabItem.eager">
            <component :is="tabItem.component" :bus="bus"></component>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </FullScreenDialog>
  </div>
</template>
<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import Vue from "vue";
import NewMovementAdjustments from "@/components/areas/warehouse/movementAdjustments/NewMovementAdjustments.vue";
import EditMovementAdjustments from "@/components/areas/warehouse/movementAdjustments/EditMovementAdjustments.vue";
import MovementAdjustmentsList from "@/components/areas/warehouse/movementAdjustments/MovementAdjustmentsList.vue";
import MovementsAdjustmentsService from "@/services/warehouse/movementAdjustments.service.js";
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import dateUtils from "@/mixins/common/dateUtils"

export default {
  name: "MovementAdjustmentsHome",
  components: {
    FullScreenDialog,
    NewMovementAdjustments,
    MovementAdjustmentsList,
    EditMovementAdjustments,
    ExportExcelTable,
  },
  data: function () {
    let props = this.$router.resolve({ name: "Warehouse" });

    return {
      dialog: true,
      routeFather: props.href,
      tab: 1,
      bus: new Vue(),
      tabs: [
        {
          key: 0,
          title: "Gestisci Inventario",
          icon: "mdi-human-dolly",
          name: "newMovementAdjustments",
          component: "NewMovementAdjustments",
        },
        {
          key: 1,
          title: "Storico Inventario",
          icon: "mdi-book",
          name: "movementAdjustmentsList",
          component: "MovementAdjustmentsList",
        },
        {
          key: 2,
          title: "Riprendi Inventario",
          icon: "mdi-dolly",
          name: "editMovementAdjustments",
          component: "editMovementAdjustments",
          eager: true,
        },
      ],
      worksheetName: "",
      fileName:"",
      fieldsToExport: {},
      isLicenseBlocked: undefined
    };
  },
  mixins:[dateUtils],
  mounted: function () {
    this.fieldsToExport = MovementsAdjustmentsService._fieldsToExport();
    this.worksheetName = "Export Inventario ";
    this.fileName =
      "Storico-Inventario_" + this._buildDateToExport(new Date()) +
      ".xls";

    let self = this
    this.bus.$on("openEditMovementAdjustments", (id) => {
      self.tab = 2;
      self.bus.$emit("editMovementAdjustments", id);
    });

    this.bus.$on('go-to-adjustments-list', () => {
      self.tab = 1;
    })

  },
  beforeDestroy: function () {
  },
  methods: {
    async fetchExcel(){
      let filters = [];
      
      let adjustmentList = await MovementsAdjustmentsService.list(0, 15000, filters)

      return adjustmentList.rows
    },
  },
  computed: {
  },
  watch: {
  },
  isLicenseBlocked:{
    bind: 'isLicenseBlocked'
  }
};
</script>

<style>
</style>